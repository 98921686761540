var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Buttons" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeButton) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _vm._v(
          " Use spinners within buttons to indicate an action is currently processing or taking place. You may also swap the label text out of the spinner element and utilize button text as needed. "
        )
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            { attrs: { variant: "outline-primary", disabled: "" } },
            [
              _c("b-spinner", { attrs: { small: "" } }),
              _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])
            ],
            1
          ),
          _c(
            "b-button",
            { attrs: { variant: "outline-primary", disabled: "" } },
            [_c("b-spinner", { attrs: { small: "" } }), _vm._v(" Loading... ")],
            1
          ),
          _c(
            "b-button",
            { attrs: { variant: "outline-primary", disabled: "" } },
            [
              _c("b-spinner", { attrs: { small: "", type: "grow" } }),
              _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])
            ],
            1
          ),
          _c(
            "b-button",
            { attrs: { variant: "outline-primary", disabled: "" } },
            [
              _c("b-spinner", { attrs: { small: "", type: "grow" } }),
              _vm._v(" Loading... ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }