var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Colored Spinners" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBorderColor) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v(
            "You can customize the color using the standard text color variants using the "
          )
        ]),
        _c("code", [_vm._v("variant")]),
        _c("span", [_vm._v(" prop.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        _vm._l(_vm.variants, function(variant) {
          return _c("b-spinner", {
            key: variant,
            staticClass: "mr-1",
            attrs: { variant: variant }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }