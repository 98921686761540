var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "match-height" },
    [
      _c("b-col", { attrs: { md: "6" } }, [_c("spinner-border")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("spinner-color")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("spinner-grow")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("spinner-grow-color")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("spinner-flex")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("spinner-float")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("spinner-text-align")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("spinner-size")], 1),
      _c("b-col", [_c("spinner-button")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }