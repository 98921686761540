var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Text Alignment" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeTextAlign) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [_vm._v("Using text alignment utility classes:")]),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("b-spinner", {
            attrs: { variant: "primary", label: "Text Centered" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }